import * as React from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../utils/useAuth";
import axios from "axios";
import {
  DataGrid,
  GridOverlay,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Add,
  Clear,
  Close,
  Edit,
  FileDownload,
  MoreVert,
  Search,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Outlet, useNavigate } from "react-router-dom";

function QuickSearchToolbar(props) {
  const { user, permissions, companyIndex } = useAuth();
  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    "groupsExport",
    () => {
      return axios.get(
        `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/groups`,
        {
          params: {
            page: -1,
          },
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );
  React.useEffect(() => {
    if (data) {
      console.log({ data });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: 2,
        mr: 1,
        ml: 1,
      }}
    >
      <Box>
        <GridToolbarColumnsButton sx={{ mr: 1 }} />
        <GridToolbarFilterButton sx={{ mr: 1 }} />
        <GridToolbarDensitySelector sx={{ mr: 1 }} />
        {/* <GridToolbarExport sx={{ mr: 1 }} /> */}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button size="small" startIcon={<FileDownload />} sx={{ mr: 1 }}>
            <CSVLink
              data={data?.data?.data || []}
              asyncOnClick={true}
              style={{ textDecoration: "inherit", color: "inherit" }}
              filename={"Merchant Portal.csv"}
              onClick={(event, done) => {
                console.log({ event, done });
                refetch();
                if (data) {
                  done();
                } else {
                  done(false);
                  alert("Please Click again!");
                }
              }}
            >
              EXPORT
            </CSVLink>
          </Button>
        )}
        {/* <GridToolbar /> */}
      </Box>
      <Box>
        <Button sx={{ mr: 2 }} onClick={props.add} startIcon={<Add />}>
          Add
        </Button>
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          label="Search"
          size="small"
          InputProps={{
            startAdornment: <Search fontSize="small" sx={{ mr: 1 }} />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <Clear fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
}

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component="span">No Rows!</Typography>
    </Box>
  );
}

export default function Group() {
  let navigate = useNavigate();

  const { user, permissions, companyIndex } = useAuth();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(5);
  const [sortModel, setSortModel] = React.useState([
    { field: "updated_at", sort: "desc" },
  ]);
  const [filterValue, setFilterValue] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [groupId, setGroupId] = React.useState();

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel.items[0]?.value) {
      const { id, ...rest } = filterModel.items?.[0];
      setFilterValue(rest);
    } else {
      setFilterValue({});
    }
  }, []);

  const {
    isLoading: programsLoading,
    data: programsData,
    error: programsError,
  } = useQuery(
    "programs",
    () => {
      return axios.get(
        `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/programs`,
        {
          params: {
            page: -1,
          },
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
      // enabled: false, // turned off by default, manual refetch is needed
    }
  );

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery(
    ["groups", page, sortModel, filterValue, searchText, perPage],
    () => {
      return axios.get(
        `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/groups`,
        {
          params: {
            page: +page + 1,
            per_page: perPage,
            sort: JSON.stringify([sortModel?.[0]?.field, sortModel?.[0]?.sort]),
            filter: JSON.stringify([
              ...(searchText
                ? [
                    {
                      columnField: "q",
                      value: JSON.stringify({
                        value: searchText,
                        columns: ["name", "description", "program_name"],
                      }),
                    },
                  ]
                : []),
              ...(Object.keys(filterValue)?.length > 0 ? [filterValue] : []),
            ]),
          },
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    }
  );

  const {
    mutate: changeStatus,
    isLoading: statusLoading,
    error: statusError,
    data: statusData,
  } = useMutation(({ id, status }) => {
    return axios.patch(
      `/groups/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
  });

  React.useEffect(() => {
    if (statusData) {
      enqueueSnackbar("Succesfully changed group status", {
        variant: "success",
      });
      refetch();
    }
  }, [statusData]);

  React.useEffect(() => {
    if (statusError) {
      enqueueSnackbar(
        statusError?.response?.data?.error?.errorMessage ||
          statusError?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [statusError]);

  React.useEffect(() => {
    if (data) {
      if (total === 0) {
        setTotal(data?.data?.meta_data?.total);
      }
    }
  }, [data]);

  const requestSearch = (searchValue) => {
    console.log({ searchValue });
    setSearchText(searchValue);
  };

  return (
    <Container
      component="main"
      disableGutters={true}
      maxWidth="xl"
      sx={{ backgroundColor: "#fff", minHeight: "100%" }}
    >
      {/* {JSON.stringify(data?.data?.data, null, 2)} */}
      <Box sx={{ height: 500, width: "100%" }}>
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box sx={{ flex: 1, height: "100%" }}>
            <DataGrid
              autoHeight
              disableColumnMenu={true}
              columns={[
                { field: "id", hide: true },
                {
                  width: 160,
                  field: "created_at",
                  headerName: "Registered time",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.created_at).format("lll"),
                },
                {
                  width: 220,
                  field: "name",
                  headerName: "Name",
                },
                {
                  width: 220,
                  field: "description",
                  headerName: "Description",
                },
                {
                  width: 160,
                  field: "program_name",
                  headerName: "Program name",
                },
                {
                  width: 140,
                  field: "user_count",
                  headerName: "Group members",
                  type: "number",
                },
                {
                  width: 160,
                  field: "updated_at",
                  headerName: "Last updated",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.updated_at).format("lll"),
                },
                {
                  field: "status",
                  width: 180,
                  type: "actions",
                  getActions: (params) => [
                    // <Alert
                    //   severity={
                    //     params?.row?.status === "ACTIVE"
                    //       ? "success"
                    //       : params?.row?.status === "PENDING"
                    //       ? "info"
                    //       : "error"
                    //   }
                    //   sx={{ my: 0.5, py: 0, borderRadius: 2 }}
                    //   action={
                    //     <Switch
                    //       checked={
                    //         params?.row?.status === "ACTIVE" ? true : false
                    //       }
                    //       disabled={statusLoading}
                    //       onChange={() =>
                    //         changeStatus({
                    //           id: params?.row?.id,
                    //           status:
                    //             params?.row?.status === "ACTIVE"
                    //               ? "INACTIVE"
                    //               : "ACTIVE",
                    //         })
                    //       }
                    //       size="small"
                    //     />
                    //   }
                    // >
                    //   <Typography
                    //     sx={{ textTransform: "capitalize" }}
                    //     variant="body2"
                    //   >
                    //     {params?.row?.status}
                    //   </Typography>
                    // </Alert>,
                    <Button
                      startIcon={<Edit />}
                      onClick={() => {
                        // console.log("params", params?.row);
                        setOpenEdit(params?.row);
                      }}
                    >
                      Edit
                    </Button>,
                    <>
                      <IconButton
                        onClick={(event) => {
                          setGroupId(params?.row?.id);
                          setAnchorEl(event.currentTarget);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                      >
                        <MenuItem
                          onClick={() => {
                            navigate(`${groupId}`);
                          }}
                        >
                          Group Members
                        </MenuItem>
                        <MenuItem onClick={() => setAnchorEl(null)}>
                          Group transaction
                        </MenuItem>
                      </Menu>
                    </>,
                  ],
                },
              ]}
              rows={data?.data?.data || []}
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              pagination
              pageSize={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              rowCount={total}
              paginationMode="server"
              onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
              onPageChange={(newPage) => setPage(newPage)}
              loading={isLoading}
              sortingMode="server"
              sortModel={sortModel}
              sortingOrder={["desc", "asc"]}
              onSortModelChange={(newModel) => setSortModel(newModel)}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                  add: () => setOpenAdd(true),
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* <Outlet /> */}

      {openAdd && (
        <AddGroupForm
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
          loading={isLoading}
          refetch={refetch}
          programsLoading={programsLoading}
          programsData={programsData}
        />
      )}

      {openEdit && (
        <EditGroupForm
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          loading={isLoading}
          refetch={refetch}
          programsLoading={programsLoading}
          programsData={programsData}
        />
      )}
    </Container>
  );
}

const AddGroupForm = ({
  loading,
  refetch,
  openAdd,
  setOpenAdd,
  programsLoading,
  programsData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, permissions, companyIndex } = useAuth();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "POST",
      url: `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/groups`,
      data,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    });
  });

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully added group!`, {
        variant: "success",
      });
      reset();
      refetch();
      setOpenAdd(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      scroll="paper"
      open={openAdd}
      // PaperProps={{elevation: 8}}
      onClose={() => setOpenAdd(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          // width: "100%",
          boxShadow: "0 2px 3px rgb(0 0 0 / 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add New Group
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            minHeight: 200,
            m: 2,
          }}
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Group name is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Group name"
                    helperText={errors?.name ? errors?.name?.message : ""}
                    error={errors?.name}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: "Desccription is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    multiline
                    rows={3}
                    label="Desccription"
                    helperText={
                      errors?.description ? errors?.description?.message : ""
                    }
                    error={errors?.description}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="company_program_id"
                control={control}
                rules={{
                  required: "Program is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    select
                    fullWidth
                    size="small"
                    label="Program"
                    helperText={
                      errors?.company_program_id
                        ? errors?.company_program_id?.message
                        : ""
                    }
                    error={errors?.company_program_id}
                    {...field}
                  >
                    {programsLoading ? (
                      <CircularProgress />
                    ) : (
                      programsData?.data?.data?.map((item) => (
                        <MenuItem
                          sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }}
                          key={item?.id}
                          value={item?.id}
                        >
                          {item?.name}
                        </MenuItem>
                      ))
                    )}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
      >
        <Button
          color="inherit"
          onClick={() => setOpenAdd(false)}
          variant="contained"
          // disabled={loading}
          sx={{ width: 120, fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          color="primary"
          onClick={handleSubmit((data) => {
            return mutate(data);
          })}
          variant="contained"
          disabled={loading}
          sx={{ width: 120, fontWeight: "bold", color: "#fff" }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditGroupForm = ({
  loading,
  refetch,
  openEdit,
  setOpenEdit,
  programsLoading,
  programsData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, permissions, companyIndex } = useAuth();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "PATCH",
      url: `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/groups/${openEdit?.id}`,
      data,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    });
  });

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully updated group!`, {
        variant: "success",
      });
      reset();
      refetch();
      setOpenEdit(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      scroll="paper"
      open={openEdit}
      // PaperProps={{elevation: 8}}
      onClose={() => setOpenEdit(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          // width: "100%",
          boxShadow: "0 2px 3px rgb(0 0 0 / 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Edit Group
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            minHeight: 200,
            m: 2,
          }}
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Group name is required",
                }}
                defaultValue={openEdit?.name}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    label="Group name"
                    helperText={errors?.name ? errors?.name?.message : ""}
                    error={errors?.name}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: "Desccription is required",
                }}
                defaultValue={openEdit?.description}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    multiline
                    rows={3}
                    label="Desccription"
                    helperText={
                      errors?.description ? errors?.description?.message : ""
                    }
                    error={errors?.description}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="company_program_id"
                control={control}
                rules={{
                  required: "Program is required",
                }}
                defaultValue={openEdit?.company_program_id}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    select
                    fullWidth
                    size="small"
                    label="Program"
                    helperText={
                      errors?.company_program_id
                        ? errors?.company_program_id?.message
                        : ""
                    }
                    error={errors?.company_program_id}
                    {...field}
                  >
                    {programsLoading ? (
                      <CircularProgress />
                    ) : (
                      programsData?.data?.data?.map((item) => (
                        <MenuItem
                          sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }}
                          key={item?.id}
                          value={item?.id}
                        >
                          {item?.name}
                        </MenuItem>
                      ))
                    )}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
      >
        <Button
          color="inherit"
          onClick={() => setOpenEdit(false)}
          variant="contained"
          // disabled={loading}
          sx={{ width: 120, fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          color="primary"
          onClick={handleSubmit((data) => {
            return mutate(data);
          })}
          variant="contained"
          disabled={loading}
          sx={{ width: 120, fontWeight: "bold", color: "#fff" }}
        >
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
