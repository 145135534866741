import * as React from "react";
import { useLocalStorage } from "./useLocalStorage";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Slide } from "@mui/material";
import { SSO_BASE_URL } from "./config";
import { queryClient } from "../App";
import { useNavigate } from "react-router-dom";

export function useAuth() {
  //   const navigate = useNavigate();
  // const full = window.location.host;
  // //window.location.host is subdomain.domain.com
  // const parts = full.split(".");
  // const sub = parts[0];
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = useLocalStorage("auth");
  const [permissions, setPermissions] = useLocalStorage("permissions");
  const [index, setIndex] = useLocalStorage("index");
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = useMutation((auth) => {
    return axios({
      method: "post",
      url: "/api/login",
      baseURL: SSO_BASE_URL,
      data: auth,
    });
    // return axios.post("/auth/login", auth);
  });

  const {
    isLoading: getPermissionsLoading,
    data,
    error,
    refetch,
    status,
  } = useQuery(
    ["access_permissions", { status: "active", user }],
    (status, user) => {
      return axios.get(`/access_permissions/${"corporate"}`, {
        headers: {
          Authorization: `Bearer ${status?.queryKey?.[1]?.user?.access_token}`,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: !!user, // turned off by default, manual refetch is needed
    }
  );

  React.useEffect(() => {
    if (permissions) {
      // console.log({ permissions });
      setLoading(false);
    }
  }, [permissions]);

  React.useEffect(() => {
    if (data) {
      setPermissions(data?.data?.data);
      if (data?.data?.data?.company_user_permissions?.length === 1) {
        setIndex(0);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      setLoading(false);
      enqueueSnackbar(
        error?.response?.data?.error?.errorMessage ||
          error?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [error]);

  const signin = async (params, callback) => {
    setLoading(true);
    return mutate(params, {
      onSuccess: async (data) => {
        setUser(data?.data?.data);

        // refetch();

        await permissions;

        // setTimeout(() => {
        //   if (callback) {
        //     setLoading(false);
        //     console.log({ permissions, callback });
        //     // callback();
        //   }
        // }, 1000);
      },
      onError: (error) => {
        setLoading(false);
        console.error({
          error,
          msg: error?.response?.data?.error?.errorMessage,
          default: error?.message,
        });
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Slide,
          }
        );
      },
    });
  };

  const saveChoosenIndex = (index) => {
    setIndex(index);
    navigate("/");
  };

  const signout = (callback) => {
    setUser(null);
    setPermissions(null);
    setIndex(0);

    queryClient.clear();

    if (callback) {
      callback();
    }
  };

  return {
    user,
    loading,
    permissions,
    signin,
    signout,
    companyIndex: index,
    saveChoosenIndex,
  };
}
