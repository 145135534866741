import { SvgIcon } from "@mui/material";

export function TransactionIcon(props) {
  return (
    <SvgIcon
      {...props}
      id="people_black_24dp_2_"
      data-name="people_black_24dp (2)"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_107645"
        data-name="Path 107645"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <path
        id="transaction_2_"
        data-name="transaction (2)"
        d="M7.275,10.669v.69a1.418,1.418,0,0,0,1.347,1.414,1.394,1.394,0,0,0,1.347-1.4c0-.654-.625-1.021-1.646-1.566L8.311,9.8a3.161,3.161,0,0,1-2.08-2.83,2.4,2.4,0,0,1,1.718-2.3V3.119H9.3V4.665a2.414,2.414,0,0,1,1.718,2.315v.69H9.666v-.69a1.044,1.044,0,0,0-2.088-.013c0,.756.418,1.107,1.355,1.607l.013.007a7.562,7.562,0,0,1,1.522.973,2.379,2.379,0,0,1,.848,1.815,2.806,2.806,0,0,1-.79,1.953,2.682,2.682,0,0,1-1.231.738v1.469H7.948V14.059a2.823,2.823,0,0,1-2.021-2.7v-.69H7.275ZM15.93,2.085V4.638a8.745,8.745,0,0,0-2.387-2.561A8.473,8.473,0,0,0,2.525,3.084,8.875,8.875,0,0,0,0,9.325H1.347A7.371,7.371,0,0,1,8.622,1.878a7.194,7.194,0,0,1,6.265,3.655H12.562V6.912h4.715V2.085ZM8.656,16.772A7.194,7.194,0,0,1,2.39,13.117H4.715V11.738H0v4.827H1.347V14.012a8.745,8.745,0,0,0,2.387,2.561,8.451,8.451,0,0,0,11-1.008,8.9,8.9,0,0,0,2.507-6.239H15.9A7.354,7.354,0,0,1,8.656,16.772Z"
        transform="translate(3.361 2.675)"
        fill="currentColor"
      />
      <path
        id="transaction_2_-_Outline"
        data-name="transaction (2) - Outline"
        d="M8.656,18.4a8.683,8.683,0,0,1-5.067-1.625A8.952,8.952,0,0,1,1.6,14.828v1.988H-.25V11.488H4.965v1.879H2.837a6.9,6.9,0,0,0,5.818,3.155,7.1,7.1,0,0,0,6.991-7.2v-.25h1.847v.25a9.148,9.148,0,0,1-2.578,6.414A8.665,8.665,0,0,1,8.656,18.4ZM1.1,13.124l.463.757a8.463,8.463,0,0,0,2.319,2.488,8.2,8.2,0,0,0,10.678-.979A8.639,8.639,0,0,0,16.99,9.575h-.848a7.6,7.6,0,0,1-7.487,7.447A7.428,7.428,0,0,1,4.9,16.005a7.573,7.573,0,0,1-2.724-2.763l-.215-.374H4.465v-.879H.25v4.327H1.1Zm8.448,2.657H7.7V14.242a3.1,3.1,0,0,1-2.021-2.884v-.94H7.525v.94a1.162,1.162,0,0,0,1.1,1.164,1.141,1.141,0,0,0,1.1-1.152c0-.484-.485-.8-1.513-1.345l-.013-.007a3.416,3.416,0,0,1-2.212-3.05A2.667,2.667,0,0,1,7.7,4.485V2.869H9.545V4.486a2.68,2.68,0,0,1,1.718,2.495v.94H9.416v-.94a.794.794,0,0,0-1.588-.013c0,.583.251.868,1.223,1.387l.013.007a7.744,7.744,0,0,1,1.572,1.008,2.62,2.62,0,0,1,.931,2,3.055,3.055,0,0,1-.86,2.127,2.962,2.962,0,0,1-1.161.749Zm-1.347-.5h.847v-1.41l.185-.05a2.443,2.443,0,0,0,1.116-.67,2.558,2.558,0,0,0,.72-1.78A2.139,2.139,0,0,0,10.3,9.74,7.379,7.379,0,0,0,8.829,8.8L8.815,8.8c-1.008-.538-1.487-.948-1.487-1.827a1.294,1.294,0,0,1,2.588.013v.44h.847v-.44A2.173,2.173,0,0,0,9.224,4.905l-.179-.053V3.369H8.2V4.852L8.019,4.9A2.162,2.162,0,0,0,6.481,6.968,2.912,2.912,0,0,0,8.428,9.577l.013.007c.954.509,1.777.948,1.777,1.786a1.647,1.647,0,0,1-1.6,1.652,1.674,1.674,0,0,1-1.6-1.664v-.44H6.178v.44a2.579,2.579,0,0,0,1.838,2.46l.183.051ZM1.6,9.575H-.25v-.25a9.123,9.123,0,0,1,2.6-6.416A8.723,8.723,0,0,1,13.689,1.874,8.952,8.952,0,0,1,15.68,3.822V1.835h1.847V7.162H12.312V5.283H14.44A6.9,6.9,0,0,0,8.622,2.128,7.12,7.12,0,0,0,1.6,9.325Zm-1.344-.5H1.1A7.615,7.615,0,0,1,8.622,1.628a7.428,7.428,0,0,1,3.757,1.017A7.573,7.573,0,0,1,15.1,5.408l.215.374H12.812v.879h4.215V2.335H16.18V5.526l-.463-.757A8.463,8.463,0,0,0,13.4,2.281,8.223,8.223,0,0,0,2.7,3.259,8.617,8.617,0,0,0,.253,9.075Z"
        transform="translate(3.361 2.675)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
