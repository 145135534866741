import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
// import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../utils/useAuth";
import { useNavigate } from "react-router";
import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";

export default function SignIn() {
  const navigate = useNavigate();
  const [chooseCompany, setChooseCompany] = React.useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const { signin, loading, permissions, saveChoosenIndex } = useAuth();

  const onSubmit = (data) =>
    signin(data, () => {
      navigate("/");
    });

  React.useEffect(() => {
    if (permissions) {
      if (permissions?.company_user_permissions?.length > 1) {
        setChooseCompany(true);
      } else {
        setChooseCompany(0);
        navigate("/");
      }
    }
  }, [permissions]);

  return (
    <Container component="main" maxWidth="xs">
      {chooseCompany ? (
        // <Box
        //   sx={{
        //     // paddingTop: 8,
        //     display: "flex",
        //     flexDirection: "column",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     backgroundColor: "red",
        //     height: "100vh",
        //   }}
        // >
        <Box
          sx={{
            // width: 400,
            display: "flex",
            flexDirection: "column",
            mt: 8,
          }}
        >
          {/* <Toolbar /> */}
          <Box
            sx={{
              backgroundColor: "#fff",
              py: 3,
              px: 4,
              width: 400,
              position: "fixed",
              top: 0,
              zIndex: 1,
              boxShadow: "0 1px 1px rgb(0 0 0 / 0.2)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Choose company
              </Typography>
              <Typography variant="body2" gutterBottom>
                please choose which company you want to sign in to
              </Typography>
            </Box>
          </Box>
          <Box sx={{ m: 4 }}>
            <Box sx={{ mt: 1 }}>
              {/* <Divider sx={{ mb: 1 }} /> */}
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {permissions?.company_user_permissions?.map((company, i) => {
                  return (
                    <ListItem
                      key={company?.name}
                      component={Paper}
                      sx={{ my: 1 }}
                      button
                      onClick={() => {
                        saveChoosenIndex(i);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          variant="square"
                          sx={{ height: "auto" }}
                          src={company?.logo}
                        />
                      </ListItemAvatar>
                      <ListItemText primary={company?.name} />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            // paddingTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "red",
            height: "100vh",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <Controller
              name="username"
              control={control}
              rules={{
                required: "Email is required",
              }}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="Email Address"
                  autoComplete="email"
                  autoFocus
                  helperText={errors?.username ? errors?.username?.message : ""}
                  error={errors?.username}
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Password is required",
              }}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="Password"
                  autoComplete="current-password"
                  type="password"
                  helperText={errors?.password ? errors?.password?.message : ""}
                  error={errors?.password}
                  {...field}
                />
              )}
            />
            {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
          </Box>
        </Box>
      )}
    </Container>
  );
}
