import { CssBaseline, Slide, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/main";
import SignIn from "./pages/signIn";
import { theme } from "./utils/theme";
import axios from "axios";
import { BASE_URL, VERSION } from "./utils/config";
import { RequireAuth } from "./components/requireAuth";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import Transaction from "./pages/transaction";
import Customer from "./pages/customer";
import Branch from "./pages/branch";
import User from "./pages/user";
import Role from "./pages/role";
import Dashboard from "./pages/dashboard";
import BankConfiguration from "./pages/bank_configuration";
import Product from "./pages/product";
import Program from "./pages/program";
import Group from "./pages/group";
import GroupDetail from "./pages/group_detail";
import { access } from "./utils/access";
import ChangePassword from "./pages/change_password";

// require("dotenv").config();
axios.defaults.baseURL = `${BASE_URL}/${VERSION}`;
// axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;

export const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionComponent={Slide}
      >
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="login" element={<SignIn />} />
              <Route path="change-password" element={<ChangePassword />} />

              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Main />
                  </RequireAuth>
                }
              >
                <Route index element={<User />} />
                {/* <Route index element={<Dashboard />} /> */}
                {/* <Route path="users" element={<User />} /> */}

                {/* {access(["get all permissions", "add role"]) && (
                  <Route path="roles" element={<Role />} />
                )} */}
                <Route path="roles" element={<Role />} />
                <Route path="groups" element={<Group />} />
                <Route path="groups/:groupId" element={<GroupDetail />} />
                <Route path="transactions" element={<Transaction />} />
                <Route path="branches" element={<Branch />} />
                <Route path="programs" element={<Program />} />
                <Route
                  path="bank-configurations"
                  element={<BankConfiguration />}
                />
                <Route path="products" element={<Product />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
