import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Slide,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../utils/useAuth";
import axios from "axios";
import {
  DataGrid,
  GridOverlay,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Add,
  Clear,
  Close,
  Edit,
  ExpandMore,
  FileDownload,
  Search,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { LocalizationProvider, TimePicker } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";

const daysOfTheWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

function QuickSearchToolbar(props) {
  const { user, permissions, companyIndex } = useAuth();
  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    "programsExport",
    () => {
      return axios.get(
        `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/programs`,
        {
          params: {
            page: -1,
          },
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );
  React.useEffect(() => {
    if (data) {
      console.log({ data });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: 2,
        mr: 1,
        ml: 1,
      }}
    >
      <Box>
        <GridToolbarColumnsButton sx={{ mr: 1 }} />
        <GridToolbarFilterButton sx={{ mr: 1 }} />
        <GridToolbarDensitySelector sx={{ mr: 1 }} />
        {/* <GridToolbarExport sx={{ mr: 1 }} /> */}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button size="small" startIcon={<FileDownload />} sx={{ mr: 1 }}>
            <CSVLink
              data={data?.data?.data || []}
              asyncOnClick={true}
              style={{ textDecoration: "inherit", color: "inherit" }}
              filename={"Merchant Portal.csv"}
              onClick={(event, done) => {
                console.log({ event, done });
                refetch();
                if (data) {
                  done();
                } else {
                  done(false);
                  alert("Please Click again!");
                }
              }}
            >
              EXPORT
            </CSVLink>
          </Button>
        )}
        {/* <GridToolbar /> */}
      </Box>
      <Box>
        <Button sx={{ mr: 2 }} onClick={props.add} startIcon={<Add />}>
          Add
        </Button>
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          label="Search"
          size="small"
          InputProps={{
            startAdornment: <Search fontSize="small" sx={{ mr: 1 }} />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <Clear fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
}

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component="span">No Rows!</Typography>
    </Box>
  );
}

export default function Program() {
  const { user, permissions, companyIndex } = useAuth();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(5);
  const [sortModel, setSortModel] = React.useState([
    { field: "updated_at", sort: "desc" },
  ]);
  const [filterValue, setFilterValue] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel.items[0]?.value) {
      const { id, ...rest } = filterModel.items?.[0];
      setFilterValue(rest);
    } else {
      setFilterValue({});
    }
  }, []);

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery(
    ["programs", page, sortModel, filterValue, searchText, perPage],
    () => {
      return axios.get(
        `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/programs`,
        {
          params: {
            page: +page + 1,
            per_page: perPage,
            sort: JSON.stringify([sortModel?.[0]?.field, sortModel?.[0]?.sort]),
            filter: JSON.stringify([
              ...(searchText
                ? [
                    {
                      columnField: "q",
                      value: JSON.stringify({
                        value: searchText,
                        columns: ["name", "description"],
                      }),
                    },
                  ]
                : []),
              ...(Object.keys(filterValue)?.length > 0 ? [filterValue] : []),
            ]),
          },
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    }
  );

  const {
    mutate: changeStatus,
    isLoading: statusLoading,
    error: statusError,
    data: statusData,
  } = useMutation(({ id, status }) => {
    return axios.patch(
      `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/programs/${id}`,
      // `/users/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
  });

  React.useEffect(() => {
    if (statusData) {
      enqueueSnackbar("Succesfully changed user status", {
        variant: "success",
      });
      refetch();
    }
  }, [statusData]);

  React.useEffect(() => {
    if (statusError) {
      enqueueSnackbar(
        statusError?.response?.data?.error?.errorMessage ||
          statusError?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [statusError]);

  React.useEffect(() => {
    if (data) {
      if (total === 0) {
        setTotal(data?.data?.meta_data?.total);
      }
    }
  }, [data]);

  const requestSearch = (searchValue) => {
    console.log({ searchValue });
    setSearchText(searchValue);
  };

  return (
    <Container
      component="main"
      disableGutters={true}
      maxWidth="xl"
      sx={{ backgroundColor: "#fff", minHeight: "100%" }}
    >
      {/* {JSON.stringify(data?.data?.data, null, 2)} */}
      <Box sx={{ height: 500, width: "100%" }}>
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box sx={{ flex: 1, height: "100%" }}>
            <DataGrid
              autoHeight
              disableColumnMenu={true}
              columns={[
                { field: "id", hide: true },
                {
                  width: 160,
                  field: "created_at",
                  headerName: "Registered time",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.created_at).format("lll"),
                },
                {
                  width: 160,
                  field: "name",
                  headerName: "Name",
                },
                {
                  width: 160,
                  field: "spending_limit_amount",
                  headerName: "Spending Limit",
                  valueGetter: (params) =>
                    params?.row?.spending_limit_amount > 0 &&
                    params?.row?.spending_limit_amount
                      ? `${params?.row?.spending_limit_amount} ETB ${params?.row?.spending_limit_duration}`
                      : "Unlimited",
                },
                {
                  width: 120,
                  field: "trip_limit_amount",
                  headerName: "Trip Limit",
                  valueGetter: (params) =>
                    params?.row?.trip_limit_amount > 0 &&
                    params?.row?.trip_limit_amount
                      ? `${params?.row?.trip_limit_amount} trips ${params?.row?.trip_limit_duration}`
                      : "Unlimited",
                },
                {
                  width: 250,
                  field: "weekdays",
                  headerName: "Time Limit",
                  valueGetter: (params) =>
                    params?.row?.weekdays?.length > 0 ||
                    params?.row?.from ||
                    params?.row?.to
                      ? `${
                          params?.row?.weekdays?.length > 0
                            ? params?.row?.weekdays?.map(
                                (index) => daysOfTheWeek[index - 1]?.[0]
                              )
                            : "Unlimited"
                        } / ${
                          params?.row?.from
                            ? moment(params?.row?.from).format("HH:mm")
                            : ""
                        } - ${
                          params?.row?.to
                            ? moment(params?.row?.to).format("HH:mm")
                            : ""
                        }`
                      : "Unlimited",
                },
                {
                  width: 160,
                  field: "updated_at",
                  headerName: "Last updated",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.updated_at).format("lll"),
                },
                {
                  field: "status",
                  width: 240,
                  type: "actions",
                  getActions: (params) => [
                    <Alert
                      severity={
                        params?.row?.status === "ACTIVE"
                          ? "success"
                          : params?.row?.status === "PENDING"
                          ? "info"
                          : "error"
                      }
                      sx={{ my: 0.5, py: 0, borderRadius: 2 }}
                      action={
                        <Switch
                          checked={
                            params?.row?.status === "ACTIVE" ? true : false
                          }
                          disabled={statusLoading}
                          onChange={() =>
                            changeStatus({
                              id: params?.row?.id,
                              status:
                                params?.row?.status === "ACTIVE"
                                  ? "INACTIVE"
                                  : "ACTIVE",
                            })
                          }
                          size="small"
                        />
                      }
                    >
                      <Typography
                        sx={{ textTransform: "capitalize" }}
                        variant="body2"
                      >
                        {params?.row?.status}
                      </Typography>
                    </Alert>,
                    <Button
                      startIcon={<Edit />}
                      onClick={() => {
                        // console.log("params", params?.row);
                        setOpenEdit(params?.row);
                      }}
                    >
                      Edit
                    </Button>,
                  ],
                },
              ]}
              rows={data?.data?.data || []}
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              pagination
              pageSize={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              rowCount={total}
              paginationMode="server"
              onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
              onPageChange={(newPage) => setPage(newPage)}
              loading={isLoading}
              sortingMode="server"
              sortModel={sortModel}
              sortingOrder={["desc", "asc"]}
              onSortModelChange={(newModel) => setSortModel(newModel)}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                  add: () => setOpenAdd(true),
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      {openAdd && (
        <AddProgramForm
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
          loading={isLoading}
          refetch={refetch}
        />
      )}

      {openEdit && (
        <EditProgramForm
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          loading={isLoading}
          refetch={refetch}
        />
      )}
    </Container>
  );
}

const AddProgramForm = ({ loading, refetch, openAdd, setOpenAdd }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, permissions, companyIndex } = useAuth();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [weekdays, setWeekdays] = React.useState([1, 2, 3, 4, 5, 6, 7]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({});

  //   const trip_limit_amount = watch("trip_limit_amount");
  //   const trip_limit_duration = watch("trip_limit_duration");

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "POST",
      url: `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/programs`,
      data,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    });
  });

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully created a program!`, {
        variant: "success",
      });
      reset();
      refetch();
      setOpenAdd(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      open={openAdd}
      // PaperProps={{elevation: 8}}
      onClose={() => setOpenAdd(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          // width: "100%",
          boxShadow: "0 2px 3px rgb(0 0 0 / 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add New Program
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          sx={{
            minHeight: 200,
            my: 2,
            px: 4,
          }}
          columnSpacing={2}
        >
          <Grid item xs={12} sm={5}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Program name is required",
              }}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Program name"
                  helperText={errors?.name ? errors?.name?.message : ""}
                  error={errors?.name}
                  {...field}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              rules={{
                required: "Program description is required",
              }}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  multiline={true}
                  rows={3}
                  label="Program description"
                  helperText={
                    errors?.description ? errors?.description?.message : ""
                  }
                  error={errors?.description}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Box sx={{ pt: 1 }} />
            <Accordion
              expanded={expanded === "spending_limit"}
              onChange={handleChange("spending_limit")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Spending limit
                  </Typography>
                  <Typography variant="body2">
                    {watch("spending_limit_amount")?.length > 0 &&
                    watch("spending_limit_duration")?.length > 0
                      ? `${watch("spending_limit_amount")} ETB ${watch(
                          "spending_limit_duration"
                        )}`
                      : "Unlimited"}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="spending_limit_amount"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          margin="normal"
                          type="number"
                          fullWidth
                          size="small"
                          label="Amount"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="spending_limit_duration"
                      control={control}
                      defaultValue="monthly"
                      render={({ field }) => (
                        <TextField
                          margin="normal"
                          fullWidth
                          select
                          size="small"
                          label="Interval"
                          helperText={
                            errors?.spending_limit_duration
                              ? errors?.spending_limit_duration?.message
                              : ""
                          }
                          error={errors?.spending_limit_duration}
                          {...field}
                        >
                          <MenuItem value="daily">Daily</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                          <MenuItem value="monthly">Monthly</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Box sx={{ pt: 1 }} />
            <Accordion
              expanded={expanded === "trip_limit"}
              onChange={handleChange("trip_limit")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Trip limit
                  </Typography>
                  <Typography variant="body2">
                    {watch("trip_limit_amount")?.length > 0 &&
                    watch("trip_limit_duration")?.length > 0
                      ? `${watch("trip_limit_amount")} trips ${watch(
                          "trip_limit_duration"
                        )}`
                      : "Unlimited"}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="trip_limit_amount"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          margin="normal"
                          type="number"
                          fullWidth
                          size="small"
                          label="Number of Trips"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="trip_limit_duration"
                      control={control}
                      defaultValue="monthly"
                      render={({ field }) => (
                        <TextField
                          margin="normal"
                          fullWidth
                          select
                          size="small"
                          label="Interval"
                          helperText={
                            errors?.trip_limit_duration
                              ? errors?.trip_limit_duration?.message
                              : ""
                          }
                          error={errors?.trip_limit_duration}
                          {...field}
                        >
                          <MenuItem value="daily">Daily</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                          <MenuItem value="monthly">Monthly</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Box sx={{ pt: 1 }} />
            <Accordion
              expanded={expanded === "time_limit"}
              onChange={handleChange("time_limit")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Time limit
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: "500" }}>
                    {weekdays?.length > 0 || watch("from") || watch("to")
                      ? `${weekdays?.map(
                          (index) => daysOfTheWeek[index - 1]
                        )} / ${
                          watch("from")
                            ? moment(watch("from")).format("HH:mm")
                            : ""
                        } - ${
                          watch("to") ? moment(watch("to")).format("HH:mm") : ""
                        }`
                      : "Unlimited"}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container columnSpacing={2}>
                  <Typography sx={{ fontWeight: "bold", px: 2 }} gutterBottom>
                    Day of the week
                  </Typography>
                  <Grid item xs={12}>
                    <ToggleButtonGroup
                      value={weekdays}
                      color="primary"
                      onChange={(event, newFormats) => {
                        console.log({ newFormats });
                        setWeekdays(newFormats);
                      }}
                    >
                      {daysOfTheWeek?.map((day, i) => (
                        <ToggleButton
                          sx={{ fontWeight: "bold" }}
                          value={i + 1}
                          aria-label="bold"
                        >
                          {day}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          checked={daysOfTheWeek?.every((item, index) =>
                            weekdays.includes(index + 1)
                          )}
                          onChange={(event) =>
                            setWeekdays(
                              event.target.checked ? [1, 2, 3, 4, 5, 6, 7] : []
                            )
                          }
                        />
                      }
                      label="All week"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="from"
                      control={control}
                      defaultValue={moment().startOf("day")}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <TimePicker
                            ampm={false}
                            label="Starting time"
                            clearable={true}
                            minTime={moment().startOf("day")}
                            maxTime={moment(watch("to"))}
                            // shouldDisableTime={(timeValue, clockType) => {
                            //   console.log({ timeValue, clockType });
                            //   if (clockType === "hours" && timeValue % 2) {
                            //     return true;
                            //   }

                            //   return false;
                            // }}
                            // maxTime={moment(watch("to"))}
                            renderInput={(params) => (
                              <TextField
                                margin="normal"
                                size="small"
                                fullWidth
                                {...params}
                              />
                            )}
                            {...field}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="to"
                      control={control}
                      defaultValue={moment().endOf("day")}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <TimePicker
                            ampm={false}
                            label="End time"
                            clearable={true}
                            minTime={moment(watch("from"))}
                            maxTime={moment().endOf("day")}
                            renderInput={(params) => (
                              <TextField
                                margin="normal"
                                size="small"
                                fullWidth
                                {...params}
                              />
                            )}
                            {...field}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          checked={
                            moment(watch("from")).isSame(
                              moment().startOf("day"),
                              "minute"
                            ) &&
                            moment(watch("to")).isSame(
                              moment().endOf("day"),
                              "minute"
                            )
                          }
                          onChange={(event) => {
                            setValue(
                              "from",
                              event.target.checked
                                ? moment().startOf("day")
                                : null
                            );
                            setValue(
                              "to",
                              event.target.checked
                                ? moment().endOf("day")
                                : null
                            );
                          }}
                        />
                      }
                      label="All day"
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
      >
        <Button
          color="inherit"
          onClick={() => setOpenAdd(false)}
          variant="contained"
          // disabled={loading}
          sx={{ width: 120, fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          color="primary"
          onClick={handleSubmit((data) => {
            const { spending_limit_amount, trip_limit_amount, ...rest } = data;
            return mutate({
              ...rest,
              weekdays,
              ...(spending_limit_amount?.length > 0 && {
                spending_limit_amount,
              }),
              ...(trip_limit_amount?.length > 0 && {
                trip_limit_amount: Number(trip_limit_amount),
              }),
            });
          })}
          variant="contained"
          disabled={loading}
          sx={{ width: 120, fontWeight: "bold", color: "#fff" }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditProgramForm = ({ loading, refetch, openEdit, setOpenEdit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, permissions, companyIndex } = useAuth();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [weekdays, setWeekdays] = React.useState([]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({});

  //   const trip_limit_amount = watch("trip_limit_amount");
  //   const trip_limit_duration = watch("trip_limit_duration");

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "PATCH",
      url: `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/programs/${openEdit?.id}`,
      data,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    });
  });

  React.useEffect(() => {
    setValue("spending_limit_amount", openEdit?.spending_limit_amount);
    setValue("spending_limit_duration", openEdit?.spending_limit_duration);
    setValue("trip_limit_amount", openEdit?.trip_limit_amount);
    setValue("trip_limit_duration", openEdit?.trip_limit_duration);
    setValue("from", moment(openEdit?.from));
    setValue("to", moment(openEdit?.to));
    setWeekdays(openEdit?.weekdays);
  }, []);

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully updated a program!`, {
        variant: "success",
      });
      reset();
      refetch();
      setOpenEdit(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      open={openEdit}
      // PaperProps={{elevation: 8}}
      onClose={() => setOpenEdit(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          // width: "100%",
          boxShadow: "0 2px 3px rgb(0 0 0 / 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Edit Program
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          sx={{
            minHeight: 200,
            my: 2,
            px: 4,
          }}
          columnSpacing={2}
        >
          <Grid item xs={12} sm={5}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Program name is required",
              }}
              defaultValue={openEdit?.name}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Program name"
                  helperText={errors?.name ? errors?.name?.message : ""}
                  error={errors?.name}
                  {...field}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              rules={{
                required: "Program description is required",
              }}
              defaultValue={openEdit?.description}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  multiline={true}
                  rows={3}
                  label="Program description"
                  helperText={
                    errors?.description ? errors?.description?.message : ""
                  }
                  error={errors?.description}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Box sx={{ pt: 1 }} />
            <Accordion
              expanded={expanded === "spending_limit"}
              onChange={handleChange("spending_limit")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Spending limit
                  </Typography>
                  <Typography variant="body2">
                    {watch("spending_limit_amount")?.length > 0 &&
                    watch("spending_limit_duration")?.length > 0
                      ? `${watch("spending_limit_amount")} ETB ${watch(
                          "spending_limit_duration"
                        )}`
                      : "Unlimited"}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="spending_limit_amount"
                      control={control}
                      defaultValue={openEdit?.spending_limit_amount}
                      render={({ field }) => (
                        <TextField
                          margin="normal"
                          type="number"
                          fullWidth
                          size="small"
                          label="Amount"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="spending_limit_duration"
                      control={control}
                      defaultValue={
                        openEdit?.spending_limit_duration || "monthly"
                      }
                      render={({ field }) => (
                        <TextField
                          margin="normal"
                          fullWidth
                          select
                          size="small"
                          label="Interval"
                          {...field}
                        >
                          <MenuItem value="daily">Daily</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                          <MenuItem value="monthly">Monthly</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Box sx={{ pt: 1 }} />
            <Accordion
              expanded={expanded === "trip_limit"}
              onChange={handleChange("trip_limit")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Trip limit
                  </Typography>
                  <Typography variant="body2">
                    {Number(watch("trip_limit_amount")) > 0 &&
                    watch("trip_limit_duration")?.length > 0
                      ? `${watch("trip_limit_amount")} trips ${watch(
                          "trip_limit_duration"
                        )}`
                      : "Unlimited"}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="trip_limit_amount"
                      control={control}
                      defaultValue={openEdit?.trip_limit_amount}
                      render={({ field }) => (
                        <TextField
                          margin="normal"
                          type="number"
                          fullWidth
                          size="small"
                          label="Number of Trips"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="trip_limit_duration"
                      control={control}
                      defaultValue={openEdit?.trip_limit_duration || "monthly"}
                      render={({ field }) => (
                        <TextField
                          margin="normal"
                          fullWidth
                          select
                          size="small"
                          label="Interval"
                          {...field}
                        >
                          <MenuItem value="daily">Daily</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                          <MenuItem value="monthly">Monthly</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Box sx={{ pt: 1 }} />
            <Accordion
              expanded={expanded === "time_limit"}
              onChange={handleChange("time_limit")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Time limit
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: "500" }}>
                    {weekdays?.length > 0 || watch("from") || watch("to")
                      ? `${weekdays?.map(
                          (index) => daysOfTheWeek[index - 1]
                        )} / ${
                          watch("from")
                            ? moment(watch("from")).format("HH:mm")
                            : ""
                        } - ${
                          watch("to") ? moment(watch("to")).format("HH:mm") : ""
                        }`
                      : "Unlimited"}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container columnSpacing={2}>
                  <Typography sx={{ fontWeight: "bold", px: 2 }} gutterBottom>
                    Day of the week
                  </Typography>
                  <Grid item xs={12}>
                    <ToggleButtonGroup
                      value={weekdays}
                      color="primary"
                      onChange={(event, newFormats) => {
                        setWeekdays(newFormats);
                      }}
                    >
                      {daysOfTheWeek?.map((day, i) => (
                        <ToggleButton
                          sx={{ fontWeight: "bold" }}
                          value={i + 1}
                          aria-label="bold"
                        >
                          {day}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          checked={daysOfTheWeek?.every((item, index) =>
                            weekdays.includes(index + 1)
                          )}
                          onChange={(event) =>
                            setWeekdays(
                              event.target.checked ? [1, 2, 3, 4, 5, 6, 7] : []
                            )
                          }
                        />
                      }
                      label="All week"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="from"
                      control={control}
                      defaultValue={
                        moment(openEdit?.from) || moment().startOf("day")
                      }
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <TimePicker
                            ampm={false}
                            label="Starting time"
                            clearable={true}
                            minTime={moment().startOf("day")}
                            maxTime={moment(watch("to"))}
                            renderInput={(params) => (
                              <TextField
                                margin="normal"
                                size="small"
                                fullWidth
                                {...params}
                              />
                            )}
                            {...field}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="to"
                      control={control}
                      defaultValue={
                        moment(openEdit?.to) || moment().endOf("day")
                      }
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <TimePicker
                            ampm={false}
                            label="End time"
                            clearable={true}
                            minTime={moment(watch("from"))}
                            maxTime={moment().endOf("day")}
                            renderInput={(params) => (
                              <TextField
                                margin="normal"
                                size="small"
                                fullWidth
                                {...params}
                              />
                            )}
                            {...field}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          checked={
                            moment(watch("from")).isSame(
                              moment().startOf("day"),
                              "minute"
                            ) &&
                            moment(watch("to")).isSame(
                              moment().endOf("day"),
                              "minute"
                            )
                          }
                          onChange={(event) => {
                            setValue(
                              "from",
                              event.target.checked
                                ? moment().startOf("day")
                                : null
                            );
                            setValue(
                              "to",
                              event.target.checked
                                ? moment().endOf("day")
                                : null
                            );
                          }}
                        />
                      }
                      label="All day"
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
      >
        <Button
          color="inherit"
          onClick={() => setOpenEdit(false)}
          variant="contained"
          // disabled={loading}
          sx={{ width: 120, fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          color="primary"
          onClick={handleSubmit((data) => {
            const { spending_limit_amount, trip_limit_amount, ...rest } = data;
            return mutate({
              ...rest,
              weekdays,
              ...(spending_limit_amount?.length > 0 && {
                spending_limit_amount,
              }),
              ...(Number(trip_limit_amount) > 0 && {
                trip_limit_amount: Number(trip_limit_amount),
              }),
            });
          })}
          variant="contained"
          disabled={loading}
          sx={{ width: 120, fontWeight: "bold", color: "#fff" }}
        >
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
