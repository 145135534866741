import * as React from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../utils/useAuth";
import axios from "axios";
import {
  DataGrid,
  GridOverlay,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Add,
  Clear,
  Close,
  Edit,
  FileDownload,
  Search,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

function QuickSearchToolbar(props) {
  const { user, permissions, companyIndex } = useAuth();
  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    "usersExport",
    () => {
      return axios.get(
        `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/companies/users`,
        {
          params: {
            page: -1,
          },
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );
  React.useEffect(() => {
    if (data) {
      console.log({ data });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: 2,
        mr: 1,
        ml: 1,
      }}
    >
      <Box>
        <GridToolbarColumnsButton sx={{ mr: 1 }} />
        <GridToolbarFilterButton sx={{ mr: 1 }} />
        <GridToolbarDensitySelector sx={{ mr: 1 }} />
        {/* <GridToolbarExport sx={{ mr: 1 }} /> */}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button size="small" startIcon={<FileDownload />} sx={{ mr: 1 }}>
            <CSVLink
              data={data?.data?.data || []}
              asyncOnClick={true}
              style={{ textDecoration: "inherit", color: "inherit" }}
              filename={"Merchant Portal.csv"}
              onClick={(event, done) => {
                console.log({ event, done });
                refetch();
                if (data) {
                  done();
                } else {
                  done(false);
                  alert("Please Click again!");
                }
              }}
            >
              EXPORT
            </CSVLink>
          </Button>
        )}
        {/* <GridToolbar /> */}
      </Box>
      <Box>
        <Button sx={{ mr: 2 }} onClick={props.add} startIcon={<Add />}>
          Add
        </Button>
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          label="Search"
          size="small"
          InputProps={{
            startAdornment: <Search fontSize="small" sx={{ mr: 1 }} />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <Clear fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
}

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component="span">No Rows!</Typography>
    </Box>
  );
}

export default function User() {
  const { user, permissions, companyIndex } = useAuth();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(5);
  const [sortModel, setSortModel] = React.useState([
    { field: "updated_at", sort: "desc" },
  ]);
  const [filterValue, setFilterValue] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel.items[0]?.value) {
      const { id, ...rest } = filterModel.items?.[0];
      setFilterValue(rest);
    } else {
      setFilterValue({});
    }
  }, []);

  const {
    isLoading: rolesLoading,
    data: rolesData,
    error: rolesError,
  } = useQuery(
    "roles",
    () => {
      return axios.get(
        `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/roles`,
        {
          // params: {
          //   page: -1,
          // },
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
      // enabled: false, // turned off by default, manual refetch is needed
    }
  );

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery(
    ["users", page, sortModel, filterValue, searchText, perPage],
    () => {
      return axios.get(
        `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/companies/users`,
        {
          params: {
            page: +page + 1,
            per_page: perPage,
            sort: JSON.stringify([sortModel?.[0]?.field, sortModel?.[0]?.sort]),
            filter: JSON.stringify([
              ...(searchText
                ? [
                    {
                      columnField: "q",
                      value: JSON.stringify({
                        value: searchText,
                        columns: [
                          "first_name",
                          "middle_name",
                          "last_name",
                          "phone",
                          "email",
                        ],
                      }),
                    },
                  ]
                : []),
              ...(Object.keys(filterValue)?.length > 0 ? [filterValue] : []),
            ]),
          },
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    }
  );

  const {
    mutate: changeStatus,
    isLoading: statusLoading,
    error: statusError,
    data: statusData,
  } = useMutation(({ id, status }) => {
    return axios.patch(
      `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/company_users/${id}`,
      // `/users/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
  });

  React.useEffect(() => {
    if (statusData) {
      enqueueSnackbar("Succesfully changed user status", {
        variant: "success",
      });
      refetch();
    }
  }, [statusData]);

  React.useEffect(() => {
    if (statusError) {
      enqueueSnackbar(
        statusError?.response?.data?.error?.errorMessage ||
          statusError?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [statusError]);

  React.useEffect(() => {
    if (data) {
      if (total === 0) {
        setTotal(data?.data?.meta_data?.total);
      }
    }
  }, [data]);

  const requestSearch = (searchValue) => {
    console.log({ searchValue });
    setSearchText(searchValue);
  };

  return (
    <Container
      component="main"
      disableGutters={true}
      maxWidth="xl"
      sx={{ backgroundColor: "#fff", minHeight: "100%" }}
    >
      {/* {JSON.stringify(data?.data?.data, null, 2)} */}
      <Box sx={{ height: 500, width: "100%" }}>
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box sx={{ flex: 1, height: "100%" }}>
            <DataGrid
              autoHeight
              disableColumnMenu={true}
              columns={[
                { field: "id", hide: true },
                {
                  width: 160,
                  field: "created_at",
                  headerName: "Registered time",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.created_at).format("lll"),
                },
                {
                  width: 220,
                  field: "first_name",
                  headerName: "Name",
                  valueGetter: (params) =>
                    `${params?.row?.first_name} ${params?.row?.middle_name} ${params?.row?.last_name}`,
                },
                {
                  width: 130,
                  field: "phone",
                  headerName: "Phone number",
                },
                {
                  width: 160,
                  field: "email",
                  headerName: "Email",
                },
                {
                  width: 160,
                  field: "role",
                  headerName: "Role",
                },
                {
                  width: 160,
                  field: "updated_at",
                  headerName: "Last updated",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.updated_at).format("lll"),
                },
                {
                  field: "status",
                  width: 160,
                  type: "actions",
                  getActions: (params) => [
                    <Alert
                      severity={
                        params?.row?.company_user?.status === "ACTIVE"
                          ? "success"
                          : params?.row?.company_user?.status === "PENDING"
                          ? "info"
                          : "error"
                      }
                      sx={{ my: 0.5, py: 0, borderRadius: 2 }}
                      action={
                        <Switch
                          checked={
                            params?.row?.company_user?.status === "ACTIVE"
                              ? true
                              : false
                          }
                          disabled={statusLoading}
                          onChange={() =>
                            changeStatus({
                              id: params?.row?.id,
                              status:
                                params?.row?.company_user?.status === "ACTIVE"
                                  ? "INACTIVE"
                                  : "ACTIVE",
                            })
                          }
                          size="small"
                        />
                      }
                    >
                      <Typography
                        sx={{ textTransform: "capitalize" }}
                        variant="body2"
                      >
                        {params?.row?.company_user?.status}
                      </Typography>
                    </Alert>,
                    // <Button
                    //   startIcon={<Edit />}
                    //   onClick={() => {
                    //     // console.log("params", params?.row);
                    //     setOpenEdit(params?.row);
                    //   }}
                    // >
                    //   Edit
                    // </Button>,
                  ],
                },
              ]}
              rows={data?.data?.data || []}
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              pagination
              pageSize={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              rowCount={total}
              paginationMode="server"
              onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
              onPageChange={(newPage) => setPage(newPage)}
              loading={isLoading}
              sortingMode="server"
              sortModel={sortModel}
              sortingOrder={["desc", "asc"]}
              onSortModelChange={(newModel) => setSortModel(newModel)}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                  add: () => setOpenAdd(true),
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      {openAdd && (
        <AddUserForm
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
          loading={isLoading}
          refetch={refetch}
          rolesData={rolesData}
          rolesLoading={rolesLoading}
        />
      )}

      {openEdit && (
        <EditUserForm
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          loading={isLoading}
          refetch={refetch}
          rolesData={rolesData}
          rolesLoading={rolesLoading}
        />
      )}
    </Container>
  );
}

const AddUserForm = ({
  loading,
  refetch,
  openAdd,
  setOpenAdd,
  rolesLoading,
  rolesData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, permissions, companyIndex } = useAuth();
  const [pageStatus, setPageStatus] = React.useState("SEARCHING");
  const [searchText, setSearchText] = React.useState();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "POST",
      url: `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/users`,
      data,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    });
  });

  const {
    isLoading: searchLoading,
    data: searchData,
    error: searchError,
    refetch: searchRefetch,
  } = useQuery(
    ["find", searchText],
    () => {
      return axios.get(
        `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/users/fetch_by_phone/${searchText}`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );

  React.useEffect(() => {
    if (searchData) {
      console.log({ searchData: searchData?.data?.data?.phone });
      if (searchData?.data?.data?.phone) {
        setPageStatus("USER_FOUND");
      }
    }
  }, [searchData]);

  React.useEffect(() => {
    if (searchError) {
      console.log({ searchError });
      setPageStatus("NOT_FOUND");
    }
  }, [searchError]);

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully added user!`, {
        variant: "success",
      });
      reset();
      refetch();
      setOpenAdd(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={
        pageStatus === "SEARCHING" || pageStatus === "NOT_FOUND" ? "xs" : "md"
      }
      scroll="paper"
      open={openAdd}
      // PaperProps={{elevation: 8}}
      onClose={() => setOpenAdd(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          // width: "100%",
          boxShadow: "0 2px 3px rgb(0 0 0 / 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add New User
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            minHeight: 200,
            m: 2,
          }}
        >
          {(pageStatus === "SEARCHING" || pageStatus === "NOT_FOUND") && (
            <TextField
              variant="outlined"
              autoFocus
              error={pageStatus === "NOT_FOUND"}
              value={searchText}
              onChange={(event) => {
                if (event.target.value?.length === 10) {
                  setSearchText(event.target.value);
                  setTimeout(() => {
                    return searchRefetch();
                  }, 100);
                } else if (event.target.value?.length < 10) {
                  return setSearchText(event.target.value);
                }
                return;
              }}
              label="Search by Phone number"
              placeholder="0911223344"
              disabled={searchLoading}
              InputProps={{
                type: "number",
                endAdornment: (
                  <IconButton
                    title="Search"
                    aria-label="Search"
                    // onClick={props.clearSearch}
                  >
                    {searchLoading ? (
                      <CircularProgress />
                    ) : (
                      <Search color="primary" />
                    )}
                  </IconButton>
                ),
              }}
            />
          )}

          {pageStatus === "NOT_FOUND" && (
            <Box>
              <Box sx={{ height: 20 }} />
              <Typography gutterBottom variant="body2">
                User is not registered, Please Register!
              </Typography>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  setPageStatus("ADD_USER");
                }}
              >
                Register
              </Button>
            </Box>
          )}

          {(pageStatus === "USER_FOUND" || pageStatus === "ADD_USER") && (
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="first_name"
                  control={control}
                  defaultValue={searchData?.data?.data?.first_name}
                  rules={{
                    required: "First name is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      disabled={!!searchData?.data?.data?.first_name}
                      fullWidth
                      size="small"
                      label="First name"
                      helperText={
                        errors?.first_name ? errors?.first_name?.message : ""
                      }
                      error={errors?.first_name}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="middle_name"
                  control={control}
                  defaultValue={searchData?.data?.data?.middle_name}
                  rules={{
                    required: "Middle name is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      disabled={!!searchData?.data?.data?.middle_name}
                      fullWidth
                      size="small"
                      label="Middle name"
                      helperText={
                        errors?.middle_name ? errors?.middle_name?.message : ""
                      }
                      error={errors?.middle_name}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="last_name"
                  control={control}
                  defaultValue={searchData?.data?.data?.last_name}
                  rules={{
                    required: "Last name is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      disabled={!!searchData?.data?.data?.last_name}
                      fullWidth
                      size="small"
                      label="Last name"
                      helperText={
                        errors?.last_name ? errors?.last_name?.message : ""
                      }
                      error={errors?.last_name}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={searchData?.data?.data?.email}
                  rules={{
                    required: "Email is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      disabled={!!searchData?.data?.data?.email}
                      fullWidth
                      size="small"
                      label="Email"
                      helperText={errors?.email ? errors?.email?.message : ""}
                      error={errors?.email}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue={searchText}
                  rules={{
                    required: "Phone number is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      disabled={!!searchData?.data?.data?.phone || !!searchText}
                      fullWidth
                      size="small"
                      type="number"
                      label="Phone number"
                      helperText={errors?.phone ? errors?.phone?.message : ""}
                      error={errors?.phone}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="gender"
                  control={control}
                  defaultValue={searchData?.data?.data?.gender || "male"}
                  rules={{
                    required: "Gender is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      disabled={!!searchData?.data?.data?.gender}
                      fullWidth
                      select
                      size="small"
                      label="Gender"
                      helperText={errors?.gender ? errors?.gender?.message : ""}
                      error={errors?.gender}
                      {...field}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="role_id"
                  control={control}
                  rules={{
                    required: "Role is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      select
                      fullWidth
                      size="small"
                      label="Role"
                      helperText={
                        errors?.role_id ? errors?.role_id?.message : ""
                      }
                      error={errors?.role_id}
                      {...field}
                    >
                      {rolesData?.data?.data?.map((item) => (
                        <MenuItem
                          sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }}
                          key={item?.id}
                          value={item?.id}
                        >
                          {item?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
      >
        <Button
          color="inherit"
          onClick={() => setOpenAdd(false)}
          variant="contained"
          // disabled={loading}
          sx={{ width: 120, fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          color="primary"
          onClick={handleSubmit((data) => {
            if (pageStatus === "USER_FOUND") {
              return mutate({
                ...(!searchData?.data?.data?.email && { email: data?.email }),
                role_id: data?.role_id,
                phone: data?.phone,
              });
            } else if (pageStatus === "ADD_USER") {
              return mutate(data);
            }
          })}
          variant="contained"
          disabled={loading}
          sx={{ width: 120, fontWeight: "bold", color: "#fff" }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditUserForm = ({
  loading,
  refetch,
  openEdit,
  setOpenEdit,
  rolesLoading,
  rolesData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "PATCH",
      url: `/users/${openEdit?.id}`,
      data,
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });
  });

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully added user!`, {
        variant: "success",
      });
      reset();
      refetch();
      setOpenEdit(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Drawer
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      anchor="right"
      open={openEdit}
      onClose={() => setOpenEdit(false)}
    >
      <Box
        sx={{
          width: 400,
          display: "flex",
          flexDirection: "column",
          mt: 12,
          mb: 12,
        }}
      >
        {/* <Toolbar /> */}
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            py: 3,
            px: 4,
            width: 400,
            position: "fixed",
            top: 0,
            zIndex: (theme) => theme.zIndex.drawer + 3,
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Edit User
            </Typography>
            <Typography variant="body2" gutterBottom>
              Edit a new system user by giving a role.
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box>
            <IconButton
              onClick={() => setOpenEdit(false)}
              sx={{ color: "#000" }}
            >
              <Close color="inherit" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ m: 4 }}>
          <Box sx={{ mt: 1 }}>
            <Controller
              name="first_name"
              control={control}
              rules={{
                required: "First name is required",
              }}
              defaultValue={openEdit?.first_name}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="First name"
                  helperText={
                    errors?.first_name ? errors?.first_name?.message : ""
                  }
                  error={errors?.first_name}
                  {...field}
                />
              )}
            />

            <Controller
              name="middle_name"
              control={control}
              rules={{
                required: "Middle name is required",
              }}
              defaultValue={openEdit?.middle_name}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Middle name"
                  helperText={
                    errors?.middle_name ? errors?.middle_name?.message : ""
                  }
                  error={errors?.middle_name}
                  {...field}
                />
              )}
            />

            <Controller
              name="last_name"
              control={control}
              rules={{
                required: "Last name is required",
              }}
              defaultValue={openEdit?.last_name}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Last name"
                  helperText={
                    errors?.last_name ? errors?.last_name?.message : ""
                  }
                  error={errors?.last_name}
                  {...field}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required",
              }}
              defaultValue={openEdit?.email}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Email"
                  helperText={errors?.email ? errors?.email?.message : ""}
                  error={errors?.email}
                  {...field}
                />
              )}
            />

            <Controller
              name="phone"
              control={control}
              rules={{
                required: "Phone number is required",
              }}
              defaultValue={openEdit?.phone}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  type="number"
                  fullWidth
                  size="small"
                  placeholder="0911223344"
                  label="Phone Number"
                  helperText={errors?.phone ? errors?.phone?.message : ""}
                  error={errors?.phone}
                  {...field}
                />
              )}
            />

            {/* <Controller
              name="password"
              control={control}
              rules={{
                required: "Password is required",
              }}
              defaultValue={openEdit?.password}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  type="password"
                  fullWidth
                  size="small"
                  label="Password"
                  helperText={errors?.password ? errors?.password?.message : ""}
                  error={errors?.password}
                  {...field}
                />
              )}
            /> */}

            {!rolesLoading && rolesData && (
              <Controller
                name="role"
                control={control}
                rules={{
                  required: "Role is required",
                }}
                defaultValue={openEdit?.role}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    select
                    fullWidth
                    size="small"
                    label="Role"
                    helperText={errors?.role ? errors?.role?.message : ""}
                    error={errors?.role}
                    {...field}
                  >
                    {rolesData?.data?.data?.map((item) => (
                      <MenuItem
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }}
                        key={item?.name}
                        value={item?.name}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            p: 4,
            width: 400,
            position: "fixed",
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            zIndex: (theme) => theme.zIndex.drawer + 3,
          }}
        >
          <Button
            color="primary"
            onClick={handleSubmit((data) => mutate(data))}
            variant="contained"
            disabled={loading}
            sx={{
              px: 4,
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
