import * as React from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Stack,
  Chip,
  Typography,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../utils/useAuth";
import axios from "axios";
import {
  DataGrid,
  GridOverlay,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Add,
  Clear,
  Close,
  Edit,
  FileDownload,
  Search,
  CheckBoxOutlineBlank,
  CheckBox,
} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { access } from "../utils/access";

function QuickSearchToolbar(props) {
  const { user, permissions, companyIndex } = useAuth();
  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    "rolesExport",
    () => {
      return axios.get(
        `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/roles`,
        {
          params: {
            page: -1,
          },
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );
  React.useEffect(() => {
    if (data) {
      console.log({ data });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: 2,
        mr: 1,
        ml: 1,
      }}
    >
      <Box>
        <GridToolbarColumnsButton sx={{ mr: 1 }} />
        <GridToolbarFilterButton sx={{ mr: 1 }} />
        <GridToolbarDensitySelector sx={{ mr: 1 }} />
        {/* <GridToolbarExport sx={{ mr: 1 }} /> */}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button size="small" startIcon={<FileDownload />} sx={{ mr: 1 }}>
            <CSVLink
              data={data?.data?.data || []}
              asyncOnClick={true}
              style={{ textDecoration: "inherit", color: "inherit" }}
              filename={"Merchant Portal.csv"}
              onClick={(event, done) => {
                console.log({ event, done });
                if (access(["get all permissions"])) {
                  refetch();
                  if (data) {
                    done();
                  } else {
                    done(false);
                    alert("Please Click again!");
                  }
                }
              }}
            >
              EXPORT
            </CSVLink>
          </Button>
        )}
        {/* <GridToolbar /> */}
      </Box>
      <Box>
        {access(["add role"]) && (
          <Button sx={{ mr: 2 }} onClick={props.add} startIcon={<Add />}>
            Add
          </Button>
        )}
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          label="Search"
          size="small"
          InputProps={{
            startAdornment: <Search fontSize="small" sx={{ mr: 1 }} />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <Clear fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </Box>
    </GridOverlay>
  );
}

function CustomNoRowsOverlay() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component="span">No Rows!</Typography>
    </Box>
  );
}

export default function Role() {
  const { user, permissions, companyIndex } = useAuth();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(5);
  const [sortModel, setSortModel] = React.useState([
    { field: "updated_at", sort: "desc" },
  ]);
  const [filterValue, setFilterValue] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [roleObj, setRoleObj] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel.items[0]?.value) {
      const { id, ...rest } = filterModel.items?.[0];
      setFilterValue(rest);
    } else {
      setFilterValue({});
    }
  }, []);

  const {
    isLoading: permissionsLoading,
    data: permissionsData,
    error: permissionsError,
  } = useQuery(
    "permissions",
    () => {
      return axios.get(
        `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/auth/permissions`,
        {
          // params: {
          //   page: -1,
          // },
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: access(["add role"]), // turned off by default, manual refetch is needed
    }
  );

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery(
    ["roles", page, sortModel, filterValue, searchText, perPage],
    () => {
      return axios.get(
        `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/roles`,
        {
          params: {
            page: +page + 1,
            per_page: perPage,
            sort: JSON.stringify([sortModel?.[0]?.field, sortModel?.[0]?.sort]),
            filter: JSON.stringify([
              ...(searchText
                ? [
                    {
                      columnField: "q",
                      value: JSON.stringify({
                        value: searchText,
                        columns: ["name"],
                      }),
                    },
                  ]
                : []),
              ...(Object.keys(filterValue)?.length > 0 ? [filterValue] : []),
            ]),
          },
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: access(["get all permissions"]),
      enabled: access(["get all permissions"]), // turned off by default, manual refetch is needed
    }
  );

  const {
    mutate: changeStatus,
    isLoading: statusLoading,
    error: statusError,
    data: statusData,
  } = useMutation(({ id, status }) => {
    return axios.patch(
      `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/roles/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
  });

  React.useEffect(() => {
    if (statusData) {
      enqueueSnackbar("Succesfully changed user status", {
        variant: "success",
      });
      refetch();
    }
  }, [statusData]);

  React.useEffect(() => {
    if (statusError) {
      enqueueSnackbar(
        statusError?.response?.data?.error?.errorMessage ||
          statusError?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [statusError]);

  React.useEffect(() => {
    if (
      permissionsData &&
      Array.isArray(permissionsData?.data?.data) &&
      permissionsData?.data?.data?.length > 0
    ) {
      setRoleObj(
        permissionsData?.data?.data?.reduce(function (roles, role) {
          if (role.category in roles) {
            roles[role.category]?.push(role);
          } else {
            roles[role.category] = [role];
          }
          return roles;
        }, {})
      );
    }
  }, [permissionsData]);

  React.useEffect(() => {
    if (data) {
      if (total === 0) {
        setTotal(data?.data?.meta_data?.total);
      }
    }
  }, [data]);

  const requestSearch = (searchValue) => {
    console.log({ searchValue });
    setSearchText(searchValue);
  };

  return (
    <Container
      component="main"
      disableGutters={true}
      maxWidth="xl"
      sx={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      {/* {JSON.stringify(data?.data?.data, null, 2)} */}
      <Box sx={{ height: 500, width: "100%" }}>
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box sx={{ flex: 1, height: "100%" }}>
            <DataGrid
              autoHeight
              disableColumnMenu={true}
              columns={[
                { field: "id", hide: true },
                {
                  width: 160,
                  field: "created_at",
                  headerName: "Registered time",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.created_at).format("lll"),
                },
                {
                  width: 160,
                  field: "name",
                  headerName: "Name",
                },

                {
                  width: 160,
                  field: "updated_at",
                  headerName: "Last updated",
                  type: "date",
                  valueGetter: (params) =>
                    moment(params?.row?.updated_at).format("lll"),
                },
                {
                  field: "status",
                  width: 240,
                  type: "actions",
                  getActions: (params) => [
                    <Alert
                      severity={
                        params?.row?.status === "ACTIVE"
                          ? "success"
                          : params?.row?.status === "PENDING"
                          ? "info"
                          : "error"
                      }
                      sx={{ my: 0.5, py: 0, borderRadius: 2 }}
                      action={
                        <Switch
                          checked={
                            params?.row?.status === "ACTIVE" ? true : false
                          }
                          disabled={statusLoading}
                          onChange={() =>
                            changeStatus({
                              id: params?.row?.id,
                              status:
                                params?.row?.status === "ACTIVE"
                                  ? "INACTIVE"
                                  : "ACTIVE",
                            })
                          }
                          size="small"
                        />
                      }
                    >
                      <Typography
                        sx={{ textTransform: "capitalize" }}
                        variant="body2"
                      >
                        {params?.row?.status}
                      </Typography>
                    </Alert>,
                    <>
                      {access(["edit role"]) && (
                        <Button
                          startIcon={<Edit />}
                          onClick={() => {
                            // console.log("params", params?.row);
                            setOpenEdit(params?.row);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </>,
                  ],
                },
              ]}
              rows={data?.data?.data || []}
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              pagination
              pageSize={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              rowCount={total}
              paginationMode="server"
              onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
              onPageChange={(newPage) => setPage(newPage)}
              loading={isLoading}
              sortingMode="server"
              sortModel={sortModel}
              sortingOrder={["desc", "asc"]}
              onSortModelChange={(newModel) => setSortModel(newModel)}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                  add: () => setOpenAdd(true),
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      {openAdd && (
        <AddRoleForm
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
          loading={isLoading}
          refetch={refetch}
          roleObj={roleObj}
          permissionsLoading={permissionsLoading}
        />
      )}

      {openEdit && (
        <EditRoleForm
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          loading={isLoading}
          refetch={refetch}
          roleObj={roleObj}
          permissionsLoading={permissionsLoading}
        />
      )}
    </Container>
  );
}

const AddRoleForm = ({
  loading,
  refetch,
  openAdd,
  setOpenAdd,
  permissionsLoading,
  roleObj,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, permissions, companyIndex } = useAuth();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const [selectedRoles, setSelectedRoles] = React.useState([]);

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "POST",
      // url: `/roles`,
      url: `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/roles`,
      data,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    });
  });

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully added role!`, {
        variant: "success",
      });
      reset();
      if (access(["get all permissions"])) {
        refetch();
      }
      setOpenAdd(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      open={openAdd}
      // PaperProps={{elevation: 8}}
      onClose={() => setOpenAdd(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          // width: "100%",
          boxShadow: "0 2px 3px rgb(0 0 0 / 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add New Role
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            minHeight: 200,
            m: 2,
          }}
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Role name is required",
            }}
            render={({ field }) => (
              <TextField
                margin="normal"
                fullWidth
                size="small"
                label="Role name"
                helperText={errors?.name ? errors?.name?.message : ""}
                error={errors?.name}
                {...field}
              />
            )}
          />

          <Box sx={{ pt: 1 }} />

          {Object.keys(roleObj)?.length > 0 &&
            Object.keys(roleObj).map(function (key) {
              return (
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {roleObj?.[key]?.[0]?.category}
                  </Typography>
                  <Grid container sx={{ my: 1 }} key={key} spacing={1}>
                    {roleObj[key]?.map((role) => {
                      return (
                        <Grid item>
                          <Chip
                            icon={
                              <IconButton
                                onClick={() => {
                                  // alert("hey little man!")
                                  if (selectedRoles?.includes(role?.name)) {
                                    setSelectedRoles((arr) => [
                                      ...arr.filter(
                                        (item) => item !== role?.name
                                      ),
                                    ]);
                                  } else {
                                    setSelectedRoles((arr) => [
                                      ...arr,
                                      role?.name,
                                    ]);
                                  }
                                }}
                              >
                                {selectedRoles?.includes(role?.name) ? (
                                  <CheckBox />
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )}
                              </IconButton>
                            }
                            label={role?.name}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              );
            })}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
      >
        <Button
          color="inherit"
          onClick={() => setOpenAdd(false)}
          variant="contained"
          // disabled={loading}
          sx={{ width: 120, fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          color="primary"
          onClick={handleSubmit((data) => {
            const obj = selectedRoles?.map((item) => ({
              name: item,
              // role: data?.name,
            }));
            mutate({ permissions: obj, name: data?.name });
          })}
          variant="contained"
          disabled={isLoading}
          sx={{ width: 120, fontWeight: "bold", color: "#fff" }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditRoleForm = ({
  loading,
  refetch,
  openEdit,
  setOpenEdit,
  permissionsLoading,
  roleObj,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, permissions: savedPermissions, companyIndex } = useAuth();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [selectedRoles, setSelectedRoles] = React.useState([]);

  const { mutate, isLoading, data, error } = useMutation((data) => {
    return axios({
      method: "PATCH",
      url: `/tenants/${savedPermissions?.company_user_permissions?.[companyIndex]?.id}/roles/${openEdit?.id}`,
      data,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    });
  });

  // const {
  //   isLoading: permissionsIsLoading,
  //   data: permissions,
  //   error: getPermissionsError,
  // } = useMutation((data) => {
  //   return axios({
  //     method: "GET",
  //     url: `/auth/role/${openEdit?.id}/permissions`,
  //     data,
  //     headers: {
  //       Authorization: `Bearer ${user?.token}`,
  //     },
  //   });
  // });

  const {
    isLoading: permissionsIsLoading,
    data: permissions,
    error: getPermissionsError,
    refetch: permissionsRefetch,
  } = useQuery(
    "rolePermissions",
    () => {
      return axios.get(
        `/tenants/${savedPermissions?.company_user_permissions?.[companyIndex]?.id}/roles/${openEdit?.id}`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );

  React.useEffect(() => {
    if (openEdit) {
      if (access(["edit role"])) {
        permissionsRefetch();
      }
    }
  }, [openEdit]);

  React.useEffect(() => {
    if (permissions) {
      console.log(permissions?.data?.data?.permissions);
      console.log(
        permissions?.data?.data?.permissions?.reduce(function (roles, role) {
          if (role.category in roles) {
            roles[role.category]?.push(role);
          } else {
            roles[role.category] = [role];
          }
          return roles;
        }, {})
      );
      setSelectedRoles(
        permissions?.data?.data?.permissions?.map(function (permission) {
          return permission?.name;
        })
      );
    }
  }, [permissions]);

  React.useEffect(() => {
    if (getPermissionsError) {
      enqueueSnackbar(
        getPermissionsError?.response?.data?.error?.errorMessage ||
          getPermissionsError?.message ||
          "Network Error!",
        {
          variant: "error",
        }
      );
    }
  }, [getPermissionsError]);

  React.useEffect(() => {
    if (data) {
      enqueueSnackbar(`Succesfully edited role!`, {
        variant: "success",
      });
      reset();
      if (access(["get all permissions"])) {
        refetch();
      }
      setOpenEdit(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      if (error?.response?.data?.error?.fieldErrors?.length > 0) {
        error?.response?.data?.error?.fieldErrors?.map((msg) => {
          enqueueSnackbar(msg || "Network Error!", {
            variant: "error",
          });
        });
      } else {
        enqueueSnackbar(
          error?.response?.data?.error?.errorMessage ||
            error?.message ||
            "Network Error!",
          {
            variant: "error",
          }
        );
      }
    }
  }, [error]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      open={openEdit}
      // PaperProps={{elevation: 8}}
      onClose={() => setOpenEdit(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          // width: "100%",
          boxShadow: "0 2px 3px rgb(0 0 0 / 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Edit Role
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            minHeight: 200,
            m: 2,
          }}
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Role name is required",
            }}
            defaultValue={openEdit?.name}
            render={({ field }) => (
              <TextField
                margin="normal"
                fullWidth
                disabled
                size="small"
                label="Role name"
                helperText={errors?.name ? errors?.name?.message : ""}
                error={errors?.name}
                {...field}
              />
            )}
          />

          <Box sx={{ pt: 1 }} />

          {!permissionsIsLoading ? (
            Object.keys(roleObj)?.length > 0 &&
            Object.keys(roleObj).map(function (key) {
              return (
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {roleObj?.[key]?.[0]?.category}
                  </Typography>
                  <Stack sx={{ my: 1 }} direction="row" key={key} spacing={1}>
                    {roleObj[key]?.map((role) => {
                      return (
                        <Chip
                          icon={
                            <IconButton
                              onClick={() => {
                                // alert("hey little man!")
                                if (selectedRoles?.includes(role?.name)) {
                                  setSelectedRoles((arr) => [
                                    ...arr.filter(
                                      (item) => item !== role?.name
                                    ),
                                  ]);
                                } else {
                                  setSelectedRoles((arr) => [
                                    ...arr,
                                    role?.name,
                                  ]);
                                }
                              }}
                            >
                              {selectedRoles?.includes(role?.name) ? (
                                <CheckBox />
                              ) : (
                                <CheckBoxOutlineBlank />
                              )}
                            </IconButton>
                          }
                          label={role?.name}
                        />
                      );
                    })}
                  </Stack>
                </Box>
              );
            })
          ) : (
            <CircularProgress />
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
      >
        <Button
          color="inherit"
          onClick={() => setOpenEdit(false)}
          variant="contained"
          // disabled={loading}
          sx={{ width: 120, fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          color="primary"
          onClick={handleSubmit((data) => {
            const obj = selectedRoles?.map((item) => ({
              name: item,
              // role: data?.name,
            }));
            mutate({ permissions: obj, name: data?.name });
          })}
          variant="contained"
          disabled={isLoading}
          sx={{ width: 120, fontWeight: "bold", color: "#fff" }}
        >
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
