import * as React from "react";
import {
  Container,
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemText,
  Stack,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Box, styled } from "@mui/system";
import {
  ChevronLeft,
  ChevronRight,
  Dashboard,
  Groups,
  Inbox,
  LockOutlined,
  Logout,
  Mail,
  ManageAccounts,
  Person,
  PersonAdd,
  RuleFolder,
  Settings,
  Store,
  SupervisedUserCircle,
  Widgets,
} from "@mui/icons-material";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../utils/useAuth";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { NavLink, useLocation, useNavigate, Outlet } from "react-router-dom";
import { TransactionIcon } from "../assets/transactionIcon";
import logo from "../assets/ridelogo.svg";
import { access } from "../utils/access";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Main() {
  const { user, signout, permissions, companyIndex } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const formatter = new Intl.NumberFormat();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = React.useState(false);

  const { isLoading, data, error } = useQuery("accounts", () => {
    return axios.get(
      `/tenants/${permissions?.company_user_permissions?.[companyIndex]?.id}/accounts`,
      {
        // params: {
        //   page: -1,
        // },
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    );
  });

  // React.useEffect(() => {
  //   console.log(location);
  // }, [location]);

  const Link = ({ to, icon, label }) => (
    <ListItem
      component={NavLink}
      to={to}
      button
      style={({ isActive }) =>
        isActive ? { color: "#FFAA00" } : { color: "white" }
      }
    >
      <ListItemIcon sx={{ color: "inherit" }}>{icon}</ListItemIcon>
      <ListItemText sx={{ color: "inherit" }} primary={label} />
    </ListItem>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" color="secondary" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            edge="start"
            sx={{
              marginRight: "36px",
              // ...(open && { display: 'none' }),
            }}
          >
            {open ? <ChevronLeft /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            RIDE Plus
          </Typography>
          {location?.pathname?.split("/")?.[
            location?.pathname?.split("/")?.length - 1
          ] !== "" && (
            <Button
              startIcon={<ChevronLeft />}
              onClick={() => {
                navigate(
                  location?.pathname?.split("/")?.[
                    location?.pathname?.split("/")?.length - 2
                  ] === ""
                    ? "/"
                    : location?.pathname?.split("/")?.[
                        Number(location?.pathname?.split("/")?.length - 2)
                      ]
                );
              }}
            >
              {location?.pathname?.split("/")?.[
                location?.pathname?.split("/")?.length - 2
              ] === ""
                ? "Home"
                : location?.pathname?.split("/")?.[
                    Number(location?.pathname?.split("/")?.length - 2)
                  ]}
            </Button>
          )}
          <Box flex={1} />
          <Box sx={{ mr: 4 }}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: 30, letterSpacing: 2 }}
              // variant="overline"
            >
              {data?.data?.data?.[0]?.balance &&
                (data?.data?.data?.[0]?.parent_id === "10022"
                  ? `-${formatter.format(data?.data?.data?.[0]?.balance)} ብር`
                  : `${data?.data?.data?.[0]?.balance} ብር`)}
            </Typography>
          </Box>
          <IconButton
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
            onClick={handleClick}
          >
            <Avatar
              sx={{ height: "auto" }}
              variant="square"
              src={permissions?.company_user_permissions?.[companyIndex]?.logo}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                my: 2,
                px: 2,
              }}
            >
              <Avatar
                sx={{ width: 72, height: "auto", mb: 2 }}
                variant="square"
                src={
                  permissions?.company_user_permissions?.[companyIndex]?.logo
                }
              />
              <Typography
                sx={{
                  color: "gray",
                  maxWidth: 200,
                }}
                noWrap
              >
                {permissions?.company_user_permissions?.[companyIndex]?.name}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "gray",
                  width: 200,
                }}
                noWrap
              >
                {user?.email}
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 2,
              }}
            >
              <Button
                variant="outlined"
                // sx={{ color: "#fff" }}
                size="small"
                onClick={() =>
                  navigate("/change-password", { state: "LOGOUT" })
                }
                startIcon={<LockOutlined fontSize="small" />}
              >
                Change password
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 2,
              }}
            >
              <Button
                variant="contained"
                sx={{ bgcolor: "gray", color: "#fff" }}
                size="small"
                onClick={() =>
                  signout(() => {
                    navigate("/login");
                  })
                }
                startIcon={<Logout fontSize="small" />}
              >
                Logout
              </Button>
            </Box>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Box sx={{ backgroundColor: "secondary.main", flex: 1 }}>
          <DrawerHeader />
          <Divider />
          <Avatar
            variant="square"
            sx={{
              height: "auto",
              // height: open ? 98 : 36,
              width: open ? 128 : 36,
              mx: "auto",
              my: open ? 2 : 1,
              transition: (theme) =>
                theme.transitions.create(["width", "height", "margin"], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            }}
            src={logo}
          />
          <List>
            {/* <Link to="/" icon={<Dashboard />} label="Dashboard" /> */}
            {/* <Link to="/users" icon={<Person />} label="Users" /> */}
            <Link to="/programs" icon={<RuleFolder />} label="Programs" />
            <Link to="/groups" icon={<Groups />} label="Groups" />
            <Link
              to="/transactions"
              icon={<TransactionIcon color="inherit" />}
              label="Transactions"
            />
            {/* <Link to="/branches" icon={<Store />} label="Branches" />
            <Link
            to="/bank-configurations"
            icon={<Settings />}
            label="Bank Configurations"
            />
          <Link to="/products" icon={<Widgets />} label="Products" /> */}
            <Link to="/" icon={<Person />} label="System users" />
            {access(["get all permissions", "add role"]) && (
              <Link to="/roles" icon={<ManageAccounts />} label="Roles" />
            )}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, backgroundColor: "#F5F5F5" }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
